import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Circle,
  HStack,
  IconButton,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  FaUserShield,
  FaUserFriends,
  FaCalendarCheck,
  FaLock,
  FaArrowRight,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaMapMarkerAlt,
  FaApple,
  FaGooglePlay,
  FaCheck,
  FaUserCheck,
  FaHeart,
  FaShieldAlt,
  FaStar,
  FaBell,
  FaCalendarAlt,
  FaComments,
  FaShare,
  FaMobile,
} from "react-icons/fa";
import { FaX, FaXTwitter, FaFacebook, FaTiktok } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const faqs = [
  {
    question: "How does Hubzo's matching system work?",
    answer:
      "Our intelligent matching system uses a combination of factors to ensure meaningful connections. We analyze your professional background, interests, and social preferences to match you with like-minded individuals. For events, we carefully curate the guest list to maintain perfect gender ratios (usually 1:1) and age ranges. We also consider factors like industry diversity and social dynamics to create the perfect mix. The more complete your profile, the better our matching system works for you.",
  },
  {
    question: "Are all members verified?",
    answer:
      "Absolutely! We have a rigorous verification process that includes: 1) Professional social media verification (LinkedIn/Instagram), 2) Photo verification to ensure authenticity, and 3) Manual review of each application. This multi-step process helps maintain the quality of our community and ensures everyone feels safe and comfortable at our events. We have a zero-tolerance policy for fake profiles or misrepresentation.",
  },
  {
    question: "What types of events does Hubzo host?",
    answer:
      "We curate a diverse range of sophisticated social experiences. These include: 1) Intimate supper clubs at trending restaurants, 2) Rooftop socials with stunning London views, 3) Weekend adventures and day trips, 4) Cultural experiences like wine tastings and art exhibitions, 5) Wellness events like group hikes and yoga brunches, and 6) Professional networking events. Each event is thoughtfully designed with the right mix of people, perfect venues, and engaging activities.",
  },
  {
    question: "How private is my profile?",
    answer:
      "Your privacy is our priority. Your profile is only visible to verified members, and you can control what information you share. We never share your personal details with third parties.",
  },
  {
    question: "What's the typical age range of members?",
    answer:
      "While we welcome professionals of all ages, most of our members are between 25-35 years old. Each event has specific age ranges to ensure meaningful connections.",
  },
  {
    question: "How do I get invited to events?",
    answer:
      "Once your profile is complete and verified, you'll receive personalized invitations to events that match your interests and preferences. The more complete your profile, the better matched the invitations.",
  },
];

export const FAQSection = ({ navigate }) => (
  <Box py={20} bg="gray.50">
    <Container maxW="container.xl">
      <VStack spacing={16}>
        {/* Section Header */}
        <VStack spacing={4} textAlign="center">
          <Text
            color="purple.600"
            fontWeight="semibold"
            fontSize="sm"
            textTransform="uppercase"
            letterSpacing="wide"
          >
            Common Questions
          </Text>
          <Heading
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="bold"
            bgGradient="linear(to-r, purple.500, blue.500)"
            bgClip="text"
          >
            Frequently Asked Questions
          </Heading>
          <Text color="gray.600" fontSize="lg" maxW="2xl">
            Everything you need to know about the Hubzo experience
          </Text>
        </VStack>

        {/* FAQ Accordion */}
        <Accordion allowMultiple width="100%" maxW="3xl">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} border="none" mb={4}>
              {({ isExpanded }) => (
                <Box
                  bg="white"
                  rounded="xl"
                  shadow="md"
                  borderWidth="1px"
                  borderColor={isExpanded ? "purple.200" : "gray.100"}
                  p={4}
                  transition="all 0.2s"
                >
                  <AccordionButton
                    p={4}
                    _hover={{ bg: "transparent" }}
                    _expanded={{ bg: "transparent" }}
                  >
                    <Box flex="1" textAlign="left">
                      <Text
                        fontWeight="semibold"
                        fontSize="lg"
                        color="gray.700"
                      >
                        {faq.question}
                      </Text>
                    </Box>
                    <AccordionIcon
                      color="purple.500"
                      transform={isExpanded ? "rotate(180deg)" : ""}
                      transition="transform 0.2s"
                    />
                  </AccordionButton>
                  <AccordionPanel pb={4} pt={2} px={4}>
                    <Text color="gray.600" fontSize="md" lineHeight="tall">
                      {faq.answer}
                    </Text>
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          ))}
        </Accordion>

        {/* Call to Action */}
        <Box textAlign="center" pt={8}>
          <Button
            size="lg"
            bgGradient="linear(to-r, purple.500, blue.500)"
            color="white"
            px={8}
            rounded="full"
            _hover={{
              bgGradient: "linear(to-r, purple.600, blue.600)",
              transform: "translateY(-2px)",
              shadow: "lg",
            }}
            onClick={() => navigate("/choose_name")}
          >
            Join Hubzo Today
          </Button>
          <Text color="gray.500" fontSize="sm" mt={4}>
            No obligations • Free to join • Verified members only
          </Text>
        </Box>
      </VStack>
    </Container>
  </Box>
);

const recentMeetups = [
  {
    title: "Rooftop Social @ Sky Garden",
    date: "Last Weekend",
    venue: "Sky Garden, 20 Fenchurch St",
    image:
      "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.20.41.png?alt=media&token=449ad663-6c9b-42a9-b10c-3a89d646651b",
    attendees: "42 members",
    highlights: ["Perfect 1:1 ratio", "Stunning views", "Craft cocktails"],
    emoji: "🌃",
    color: "blue",
  },
  {
    title: "Wine Tasting Evening",
    date: "2 days ago",
    venue: "Vagabond Wines, Battersea",
    image:
      "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.33.00%20(1).png?alt=media&token=0da247bc-154e-47a7-b774-95bb745bad11",
    attendees: "28 members",
    highlights: ["6 premium wines", "Cheese pairing", "Expert sommelier"],
    emoji: "🍷",
    color: "purple",
  },
  {
    title: "Seven Sisters Coastal Hike",
    date: "Last Sunday",
    venue: "Seven Sisters Country Park",
    image:
      "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/58883038_853599651659499_7115764480138543104_n.jpg?alt=media&token=568ad978-26d0-43f0-8c2b-9b96bfb9cca9",
    attendees: "32 members",
    highlights: ["8-mile coastal walk", "Pub lunch", "Beach picnic"],
    emoji: "🏃‍♂️",
    color: "green",
  },
];

const RecentMeetupsSection = ({ navigate }) => (
  <Box py={20} bg="white" position="relative" overflow="hidden">
    {/* Fun background elements */}
    <Box
      position="absolute"
      top="5%"
      right="10%"
      fontSize="120px"
      opacity={0.1}
      transform="rotate(10deg)"
    ></Box>
    <Box
      position="absolute"
      bottom="15%"
      left="5%"
      fontSize="100px"
      opacity={0.1}
      transform="rotate(-15deg)"
    ></Box>

    <Container maxW="container.xl" position="relative" zIndex={1}>
      <VStack spacing={16}>
        {/* Section Header */}
        <VStack spacing={4} textAlign="center">
          <Box
            bg="purple.50"
            px={6}
            py={2}
            rounded="full"
            transform="rotate(-1deg)"
          >
            <Text
              color="purple.600"
              fontWeight="bold"
              fontSize="md"
              letterSpacing="wide"
            >
              ✨ FOMO Alert: You missed these! ✨
            </Text>
          </Box>
          <Heading
            fontSize={{ base: "3xl", md: "4xl" }}
            fontWeight="black"
            bgGradient="linear(to-r, purple.500, blue.500)"
            bgClip="text"
            transform="rotate(-1deg)"
          >
            Latest London Hangouts
          </Heading>
        </VStack>

        {/* Meetups Grid */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          {recentMeetups.map((meetup, index) => (
            <MotionBox
              key={index}
              initial={{ opacity: 0, y: 20, rotate: -5 }}
              whileInView={{
                opacity: 1,
                y: 0,
                rotate: 0,
                transition: {
                  duration: 0.6,
                  delay: index * 0.2,
                  type: "spring",
                  stiffness: 100,
                },
              }}
              whileHover={{
                scale: 1.03,
                rotate: 1,
                transition: { duration: 0.2 },
              }}
              viewport={{ once: true }}
            >
              <Box
                bg="white"
                rounded="2xl"
                overflow="hidden"
                borderWidth="2px"
                borderColor={`${meetup.color}.100`}
                shadow="lg"
                position="relative"
                transform={index % 2 === 0 ? "rotate(1deg)" : "rotate(-1deg)"}
              >
                {/* Event Image */}
                <Box position="relative" h="200px">
                  <Image
                    src={meetup.image}
                    alt={meetup.title}
                    objectFit="cover"
                    w="full"
                    h="full"
                  />
                  <Box
                    position="absolute"
                    top={4}
                    left={4}
                    bg="white"
                    px={3}
                    py={1}
                    rounded="full"
                    fontSize="lg"
                    fontWeight="bold"
                    shadow="lg"
                    border="2px solid"
                    borderColor={`${meetup.color}.200`}
                    transform="rotate(-2deg)"
                  >
                    {meetup.emoji} {meetup.date}
                  </Box>
                </Box>

                {/* Content */}
                <Box p={6}>
                  <VStack align="start" spacing={4}>
                    <Heading
                      size="md"
                      color="gray.800"
                      transform="rotate(-1deg)"
                    >
                      {meetup.title}
                    </Heading>

                    <HStack
                      color="gray.600"
                      fontSize="sm"
                      bg={`${meetup.color}.50`}
                      px={3}
                      py={2}
                      rounded="xl"
                      transform="rotate(1deg)"
                    >
                      <Icon as={FaMapMarkerAlt} />
                      <Text fontWeight="medium">{meetup.venue}</Text>
                    </HStack>

                    {/* Highlights */}
                    <Wrap spacing={2}>
                      {meetup.highlights.map((highlight, i) => (
                        <WrapItem key={i}>
                          <Box
                            bg={`${meetup.color}.50`}
                            color={`${meetup.color}.600`}
                            px={3}
                            py={1}
                            rounded="full"
                            fontSize="xs"
                            fontWeight="bold"
                            transform={
                              i % 2 === 0 ? "rotate(1deg)" : "rotate(-1deg)"
                            }
                            _hover={{
                              transform: "rotate(0deg) scale(1.05)",
                              transition: "all 0.2s",
                            }}
                          >
                            {highlight}
                          </Box>
                        </WrapItem>
                      ))}
                    </Wrap>

                    {/* Attendees */}
                    <HStack
                      spacing={2}
                      bg={`${meetup.color}.50`}
                      px={4}
                      py={2}
                      rounded="full"
                      transform="rotate(-1deg)"
                    >
                      <Icon as={FaUserFriends} color={`${meetup.color}.500`} />
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color={`${meetup.color}.600`}
                      >
                        {meetup.attendees}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </Box>
            </MotionBox>
          ))}
        </SimpleGrid>

        {/* Fun CTA */}
        <MotionBox
          initial={{ scale: 0.95 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Box
            bg="purple.50"
            p={8}
            rounded="2xl"
            maxW="2xl"
            w="full"
            textAlign="center"
            transform="rotate(-1deg)"
            border="3px dashed"
            borderColor="purple.200"
          >
            <VStack spacing={4}>
              <Text fontSize="4xl">🎯</Text>
              <Heading size="md" color="purple.700" transform="rotate(1deg)">
                Don't Miss Out on the Fun!
              </Heading>
              <Text color="purple.600">
                Join the community to get invited to our next exclusive meetup
              </Text>
              <Button
                size="lg"
                bgGradient="linear(to-r, purple.500, blue.500)"
                color="white"
                _hover={{
                  bgGradient: "linear(to-r, purple.600, blue.600)",
                  transform: "translateY(-2px) rotate(1deg)",
                }}
                rightIcon={<FaArrowRight />}
                onClick={() => navigate("/choose_name")}
                transform="rotate(1deg)"
              >
                Join the Fun
              </Button>
            </VStack>
          </Box>
        </MotionBox>
      </VStack>
    </Container>
  </Box>
);

const SimplifiedLandingPage = ({ navigate }) => (
  <Box minH="100vh" bg="white">
    <Container maxW="container.xl" py={20}>
      <VStack spacing={12} align="center" textAlign="center">
        {/* Logo/Branding */}
        <Box
          as="h1"
          fontSize="4xl"
          fontWeight="900"
          bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
          bgClip="text"
          letterSpacing="-0.03em"
        >
          hubzo
        </Box>

        {/* Main Content */}
        <VStack spacing={6} maxW="xl">
          <Heading fontSize={{ base: "3xl", md: "4xl" }} lineHeight="shorter">
            Welcome to London's Exclusive{" "}
            <Text
              as="span"
              bgGradient="linear(to-r, purple.500, blue.500)"
              bgClip="text"
            >
              Social Club
            </Text>
          </Heading>

          <Text fontSize="lg" color="gray.600">
            Get invited to exclusive meetups with verified members who match
            your interests
          </Text>

          {/* Main CTA Button */}
          <Button
            size="lg"
            w="full"
            h={14}
            bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
            color="white"
            fontSize="lg"
            fontWeight="bold"
            rounded="full"
            rightIcon={<FaArrowRight />}
            onClick={() => navigate("/choose_name")}
            _hover={{
              transform: "translateY(-2px)",
              shadow: "lg",
              opacity: 0.9,
            }}
          >
            Get Started
          </Button>

          {/* Login CTA */}
          <Box pt={6} w="full" maxW="md">
            <Button
              variant="outline"
              size="lg"
              w="full"
              h={14}
              fontSize="lg"
              fontWeight="bold"
              rounded="full"
              borderWidth={2}
              onClick={() => navigate("/sign_in")}
              _hover={{
                transform: "translateY(-2px)",
                shadow: "md",
                opacity: 0.9,
              }}
            >
              Already have an account? Sign in
            </Button>
          </Box>
        </VStack>
      </VStack>
    </Container>
  </Box>
);

const LandingPage = () => {
  const navigate = useNavigate();
  const [isPWA, setIsPWA] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isReturningUser, setIsReturningUser] = useState(false);

  useEffect(() => {
    // Check if running as PWA or native app
    const checkIfPWA = () => {
      // Check if running as standalone PWA
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;

      // Check if running from App Store or Play Store
      const isNative = () => {
        // iOS App Store
        const isIOSApp = window.navigator.standalone === true;

        // Check for native Android or iOS app
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isAndroidApp = /hubzo.*android/.test(userAgent);
        const isIOSNative =
          /hubzo.*ios/.test(userAgent) || /iphone.*hubzo/.test(userAgent);

        return isIOSApp || isAndroidApp || isIOSNative;
      };

      return isStandalone || isNative();
    };

    setIsPWA(checkIfPWA());

    // Rest of your existing useEffect code...
    const auth = getAuth();
    const lastSignInTime = localStorage.getItem("lastSignInTime");
    setIsReturningUser(!!lastSignInTime);

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", () => {});
    };
  }, []);

  // If running as PWA or native app, show simplified UI
  if (isPWA) {
    return <SimplifiedLandingPage navigate={navigate} />;
  }

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === "accepted") {
      setDeferredPrompt(null);
    }
  };

  const colors = {
    textColor: "#2D3748",
    bgColor: "white",
    accentColor: "#2B6CB0",
    accentColorHover: "#2C5282",
    subtitleColor: "#718096",
    cardBg: "gray.50",
    borderColor: "gray.100",
    gradientStart: "rgba(79, 70, 229, 0.1)",
    gradientEnd: "rgba(124, 58, 237, 0.1)",
    primaryGradient: "linear-gradient(135deg, #2B6CB0 0%, #4299E1 100%)",
  };

  const primaryButtonStyles = {
    bg: colors.primaryGradient,
    color: "white",
    px: 8,
    h: 14,
    fontSize: "lg",
    fontWeight: "semibold",
    rounded: "full",
    _hover: {
      transform: "translateY(-2px)",
      shadow: "lg",
      opacity: 0.95,
    },
    transition: "all 0.2s",
  };

  const secondaryButtonStyles = {
    variant: "outline",
    px: 8,
    h: 14,
    fontSize: "lg",
    fontWeight: "semibold",
    rounded: "full",
    borderWidth: 2,
    _hover: {
      transform: "translateY(-2px)",
      shadow: "md",
      bg: "whiteAlpha.100",
    },
    transition: "all 0.2s",
  };

  const cardStyles = {
    bg: colors.bgColor,
    rounded: "2xl",
    overflow: "hidden",
    borderWidth: "1px",
    borderColor: colors.borderColor,
    shadow: "sm",
    _hover: {
      transform: "translateY(-2px)",
      shadow: "md",
      transition: "all 0.2s",
    },
  };

  const sectionHeadingStyles = {
    size: "2xl",
    fontWeight: "extrabold",
    letterSpacing: "-0.02em",
    bgGradient: colors.primaryGradient,
    bgClip: "text",
  };

  const stats = [
    { number: "1:1", label: "Gender Ratio" },
    { number: "100%", label: "Verified Members" },
    { number: "90%", label: "Match Rate" },
  ];

  const steps = [
    {
      step: "1",
      title: "Quick Application",
      description: "Share your interests, lifestyle, and social preferences.",
      image: "https://illustrations.popsy.co/amber/profile.svg",
      highlight: "2 min process",
      color: colors.accentColor,
    },
    {
      step: "2",
      title: "Get Verified",
      description:
        "We ensure all members are real and meet our community standards.",
      image: "https://illustrations.popsy.co/amber/verified.svg",
      highlight: "Quality Check",
      color: colors.accentColor,
    },
    {
      step: "3",
      title: "Receive Invites",
      description: "Get invited to events with people who match your profile.",
      image: "https://illustrations.popsy.co/amber/party.svg",
      highlight: "Perfect Mix",
      color: colors.accentColor,
    },
  ];

  const popularMeetups = [
    {
      title: "Rooftop Socials",
      icon: "🥂",
      color: colors.accentColor,
      description: "Evening drinks at London's finest rooftop bars",
      demographics: "25-35 • Young Professionals",
      frequency: "Weekly",
      avgAttendees: "40 guests • 1:1 ratio",
    },
    {
      title: "Supper Club",
      icon: "✨",
      color: colors.accentColor,
      description: "Dining experiences at trending restaurants",
      demographics: "27-37 • Foodies & Socialites",
      frequency: "Bi-weekly",
      avgAttendees: "24 guests • Perfect mix",
    },
    {
      title: "Weekend Escapes",
      icon: "🌅",
      color: colors.accentColor,
      description: "Curated weekend trips within the UK",
      demographics: "25-35 • Adventure Seekers",
      frequency: "Monthly",
      avgAttendees: "30 guests • 1:1 ratio",
    },
  ];

  const testimonials = [
    {
      name: "Sarah M.",
      role: "Product Designer",
      image:
        "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.20.41.png?alt=media&token=449ad663-6c9b-42a9-b10c-3a89d646651b", // Replace with real event photo
      quote:
        "Finally, a social club that gets it! Met the coolest people at my first rooftop social. The vibe was perfect - no awkward networking, just genuine connections.",
      highlight: "First-time member & already hooked! 🎯",
      eventType: "Rooftop Social @ Sky Garden",
      joinedDate: "August 2024",
    },
    {
      name: "Alex K.",
      role: "Tech Entrepreneur",
      image:
        "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.33.00%20(1).png?alt=media&token=0da247bc-154e-47a7-b774-95bb745bad11", // Replace with real event photo
      quote:
        "Love how Hubzo keeps the groups small and well-matched. Had amazing conversations at the supper club - from startup ideas to travel stories!",
      highlight: "Best supper club in London! 🍷",
      eventType: "Supper Club @ Sketch",
      joinedDate: "September 2024",
    },
    {
      name: "Maya R.",
      role: "Creative Director",
      image:
        "https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-09%20at%2002.26.51.png?alt=media&token=3cec66d5-6866-45f2-a6cd-640abbbcf4c6", // Replace with real event photo
      quote:
        "The weekend escape was exactly what I needed! Great mix of people, perfect organization, and made some genuine friends. Can't wait for the next one!",
      highlight: "Weekend adventure squad found! 🌄",
      eventType: "Weekend Escape to Cotswolds",
      joinedDate: "September 2024",
    },
  ];

  const TopBanner = () => (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
      bg="white"
      h={{ base: "56px", md: "64px" }}
      backdropFilter="blur(12px)"
      backgroundColor="rgba(255, 255, 255, 0.9)"
      borderBottom="1px"
      borderColor="gray.100"
    >
      <Container maxW="container.xl" h="full">
        <Flex
          justify="space-between"
          align="center"
          h="full"
          px={{ base: 3, md: 4 }}
        >
          {/* Logo */}
          <Box
            as="h1"
            fontSize={{ base: "26px", md: "32px" }}
            fontWeight="900"
            letterSpacing="-0.03em"
            position="relative"
            display="inline-block"
            transform="translateY(-1px)"
            cursor="pointer"
            transition="all 0.3s ease"
            _hover={{ transform: "translateY(-2px) scale(1.02)" }}
          >
            <Box
              as="span"
              position="relative"
              bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
              bgClip="text"
              _before={{
                content: '""',
                position: "absolute",
                left: "-6px",
                right: "-6px",
                top: "-6px",
                bottom: "-6px",
                background: "linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)",
                opacity: 0.15,
                borderRadius: "xl",
                transform: "rotate(-2deg)",
                transition: "all 0.3s ease",
                zIndex: -1,
              }}
              _after={{
                content: '""',
                position: "absolute",
                left: "-3px",
                right: "-3px",
                top: "-3px",
                bottom: "-3px",
                background: "white",
                opacity: 0.9,
                borderRadius: "lg",
                transform: "rotate(-1deg)",
                transition: "all 0.3s ease",
                zIndex: -1,
              }}
            >
              hubzo
            </Box>
          </Box>

          {/* CTAs */}
          <Flex gap={2} align="center">
            {isReturningUser ? (
              <Button
                size={{ base: "md", md: "lg" }}
                bg={colors.accentColor}
                color="white"
                px={8}
                fontWeight="semibold"
                onClick={() => navigate("/sign_in")}
                leftIcon={<FaUserCheck size={16} />}
                _hover={{
                  transform: "translateY(-1px)",
                  bg: colors.accentColorHover,
                  shadow: "md",
                }}
                transition="all 0.2s"
              >
                Welcome Back
              </Button>
            ) : (
              <>
                {/* <Button
                  size={{ base: "sm", md: "md" }}
                  variant="ghost"
                  color={colors.textColor}
                  fontWeight="medium"
                  onClick={() => navigate("/sign_in")}
                >
                  Sign In
                </Button> */}
                <Button
                  size={{ base: "sm", md: "md" }}
                  bgGradient="linear(135deg, #4F46E5 0%, #7C3AED 100%)"
                  color="white"
                  _hover={{
                    transform: "translateY(-1px)",
                    bgGradient: "linear(135deg, #4F46E5 0%, #7C3AED 100%)",
                    opacity: 0.9,
                    shadow: "md",
                  }}
                  fontWeight="semibold"
                  onClick={() => navigate("/sign_in")}
                  transition="all 0.2s"
                  px={{ base: 4, md: 6 }}
                >
                  Sign In
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );

  const PWAInstallSection = () =>
    deferredPrompt && (
      <Box py={8} bg="purple.50">
        <Container maxW="container.xl">
          <VStack spacing={6} align="center" textAlign="center">
            <Heading size="lg">Install Hubzo App</Heading>
            <Text color="gray.600" maxW="2xl">
              Get the best experience by installing Hubzo on your device. Access
              your events, messages, and connections with just one tap.
            </Text>

            <HStack spacing={8} wrap="wrap" justify="center">
              <VStack align="start" bg="white" p={4} rounded="xl" shadow="sm">
                <HStack color="green.500">
                  <Icon as={FaCheck} />
                  <Text fontWeight="medium">Works offline</Text>
                </HStack>
                <HStack color="green.500">
                  <Icon as={FaCheck} />
                  <Text fontWeight="medium">Faster loading</Text>
                </HStack>
                <HStack color="green.500">
                  <Icon as={FaCheck} />
                  <Text fontWeight="medium">App-like experience</Text>
                </HStack>
              </VStack>

              <Button
                size="lg"
                onClick={handleInstallClick}
                leftIcon={<Icon as={FaApple} />}
                rightIcon={<FaArrowRight />}
                colorScheme="purple"
              >
                Install Now
              </Button>
            </HStack>

            <Text fontSize="sm" color="gray.500">
              No app store needed • Install directly from browser
            </Text>
          </VStack>
        </Container>
      </Box>
    );

  const TestimonialsSection = () => (
    <Box py={20} bg="white">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          {/* Section Header */}
          <VStack spacing={4} textAlign="center">
            <Text
              color="purple.600"
              fontWeight="semibold"
              fontSize="sm"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Early Members Love Us
            </Text>
            <Heading
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="bold"
              bgGradient="linear(to-r, purple.500, blue.500)"
              bgClip="text"
            >
              The Hubzo Experience
            </Heading>
            <Text color="gray.600" fontSize="lg" maxW="2xl">
              Join our growing community of fun-loving professionals in London
            </Text>
          </VStack>

          {/* Testimonials Grid */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
            {testimonials.map((testimonial, index) => (
              <MotionBox
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <Box
                  bg="white"
                  rounded="2xl"
                  overflow="hidden"
                  borderWidth="1px"
                  borderColor="gray.100"
                  shadow="lg"
                  position="relative"
                  _hover={{
                    transform: "translateY(-8px)",
                    shadow: "xl",
                  }}
                  transition="all 0.3s"
                >
                  {/* Event Image Section */}
                  <Box position="relative" h="200px">
                    <Image
                      src={testimonial.image}
                      alt={testimonial.eventType}
                      objectFit="cover"
                      w="full"
                      h="full"
                    />
                    {/* Fun badge overlay */}
                    <Box
                      position="absolute"
                      top={4}
                      right={4}
                      bg="white"
                      color="purple.500"
                      px={3}
                      py={1}
                      rounded="full"
                      fontSize="sm"
                      fontWeight="bold"
                      shadow="md"
                      transform="rotate(3deg)"
                    >
                      {testimonial.eventType.split("@")[0]}
                    </Box>
                  </Box>

                  {/* Content Section */}
                  <Box p={6}>
                    {/* Member Info */}
                    <HStack mb={4} spacing={3}>
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="bold" fontSize="lg" color="gray.800">
                          {testimonial.name}
                        </Text>
                        <Text fontSize="sm" color="gray.600">
                          {testimonial.role}
                        </Text>
                      </VStack>
                    </HStack>

                    {/* Highlight Badge */}
                    <Box
                      bg="purple.50"
                      color="purple.600"
                      px={4}
                      py={2}
                      rounded="xl"
                      fontSize="sm"
                      fontWeight="medium"
                      mb={4}
                      display="inline-block"
                      transform="rotate(-1deg)"
                    >
                      {testimonial.highlight}
                    </Box>

                    {/* Quote */}
                    <Text
                      color="gray.700"
                      fontSize="md"
                      lineHeight="tall"
                      fontStyle="italic"
                      mb={4}
                    >
                      "{testimonial.quote}"
                    </Text>

                    {/* Event Details */}
                    <HStack
                      spacing={2}
                      bg="gray.50"
                      p={3}
                      rounded="xl"
                      fontSize="sm"
                      color="gray.600"
                    >
                      <Icon as={FaCalendarCheck} />
                      <Text>{testimonial.eventType}</Text>
                      <Text color="gray.400">•</Text>
                      <Text>{testimonial.joinedDate}</Text>
                    </HStack>
                  </Box>
                </Box>
              </MotionBox>
            ))}
          </SimpleGrid>

          {/* Early Access Note */}
          <Box bg="purple.50" p={6} rounded="2xl" maxW="2xl" textAlign="center">
            <Text fontSize="lg" color="purple.700" fontWeight="medium">
              🚀 Just Launched in London! Early members get priority access to
              exclusive events
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );

  return (
    <Box
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position="relative"
      bg={colors.bgColor}
      bgGradient={`linear(to-b, ${colors.gradientStart}, ${colors.bgColor})`}
    >
      <TopBanner />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="auto"
        pb={8}
      >
        {/* Wrap all content sections in a Box with padding-top to account for sticky header */}
        <Box pt="0">
          {/* Show PWA install section after hero for returning users */}
          {isReturningUser && <PWAInstallSection />}

          {/* Hero Section */}
          <Container
            maxW="container.xl"
            pt={{ base: 4, md: 20 }}
            pb={{ base: 12, md: 16 }}
          >
            <Grid
              templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
              gap={{ base: 4, lg: 16 }}
              alignItems="center"
            >
              <MotionBox order={{ base: 2, lg: 1 }}>
                <VStack align="center" spacing={{ base: 4, lg: 6 }}>
                  <Heading
                    fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
                    fontWeight="black"
                    lineHeight="1.1"
                    letterSpacing="-0.02em"
                    textAlign="center"
                  >
                    <Box
                      as="span"
                      display="block"
                      bgGradient="linear(to-r, #FF3366, #FF6B3D)"
                      bgClip="text"
                      mb={2}
                    >
                      London's Exclusive
                    </Box>
                    <Box
                      as="span"
                      display="block"
                      bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                      bgClip="text"
                    >
                      IRL Social Club
                    </Box>
                  </Heading>

                  <Text
                    fontSize={{ base: "lg", md: "xl" }}
                    color="gray.600"
                    maxW="lg"
                    textAlign="center"
                    fontWeight="medium"
                  >
                    Get invited to exclusive meetups with guaranteed gender
                    ratios, age ranges, and people who match your lifestyle.
                  </Text>

                  {/* Replace Feature Pills with Stats */}
                  <HStack spacing={{ base: 4, md: 8 }} pt={2} justify="center">
                    <VStack spacing={1}>
                      <Text 
                        fontSize={{ base: "xl", md: "2xl" }}
                        fontWeight="bold"
                        bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                        bgClip="text"
                      >
                        3,000+
                      </Text>
                      <Text 
                        fontSize="sm" 
                        color="gray.600"
                        fontWeight="medium"
                      >
                        Members
                      </Text>
                    </VStack>

                    <Text color="gray.300" fontSize="2xl">•</Text>

                    <VStack spacing={1}>
                      <Text 
                        fontSize={{ base: "xl", md: "2xl" }}
                        fontWeight="bold"
                        bgGradient="linear(to-r, #FF3366, #FF6B3D)"
                        bgClip="text"
                      >
                        100+
                      </Text>
                      <Text 
                        fontSize="sm" 
                        color="gray.600"
                        fontWeight="medium"
                      >
                        Events
                      </Text>
                    </VStack>

                    <Text color="gray.300" fontSize="2xl">•</Text>

                    <VStack spacing={1}>
                      <Text 
                        fontSize={{ base: "xl", md: "2xl" }}
                        fontWeight="bold"
                        bgGradient="linear(to-r, #4F46E5, #7C3AED)"
                        bgClip="text"
                      >
                        Since
                      </Text>
                      <Text 
                        fontSize="sm" 
                        color="gray.600"
                        fontWeight="medium"
                      >
                        Aug '24
                      </Text>
                    </VStack>
                  </HStack>

                  {/* Remove or comment out Feature Pills section:
                  <HStack spacing={{ base: 3, md: 4 }} pt={2}>
                    <Box
                      bg="purple.50"
                      px={4}
                      py={2}
                      rounded="full"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Icon as={FaLock} color="purple.500" boxSize={3} />
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        color="purple.700"
                      >
                        Private Profiles
                      </Text>
                    </Box>

                    <Box
                      bg="blue.50"
                      px={4}
                      py={2}
                      rounded="full"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Icon as={FaUserFriends} color="blue.500" boxSize={3} />
                      <Text fontSize="sm" fontWeight="medium" color="blue.700">
                        Smart Matching
                      </Text>
                    </Box>
                  </HStack>
                  */}

                  {/* Main CTA */}
                  <Box position="relative" mt={2} w="full" maxW="md">
                    {/* Glow effect */}
                    <Box
                      position="absolute"
                      inset={0}
                      bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                      opacity={0.15}
                      filter="blur(20px)"
                      transform="scale(0.95)"
                      rounded="full"
                    />

                    <Button
                      size="lg"
                      w="full"
                      h={16}
                      fontSize="xl"
                      fontWeight="bold"
                      bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                      color="white"
                      rounded="full"
                      rightIcon={<FaArrowRight size={20} />}
                      onClick={() => navigate("/choose_name")}
                      _hover={{
                        transform: "translateY(-2px) scale(1.02)",
                        shadow: "xl",
                      }}
                      _active={{
                        transform: "translateY(1px)",
                      }}
                      transition="all 0.2s"
                    >
                      Get Started
                    </Button>

                    {/* Trust indicators */}

                    {false && (
                      <HStack
                        justify="center"
                        mt={4}
                        spacing={3}
                        color="gray.600"
                      >
                        <Icon as={FaShieldAlt} />
                        <Text fontSize="sm" fontWeight="medium">
                          The better your profile, the more exclusive your
                          invites
                        </Text>
                      </HStack>
                    )}
                  </Box>

                  {/* App Store CTAs */}
                  <Box pt={6} w="full" maxW="md">
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      mb={4}
                      textAlign="center"
                    >
                      Or download our mobile app
                    </Text>
                    <Stack
                      direction="row" // Always horizontal
                      spacing={{ base: 2, sm: 4 }} // Reduced spacing on mobile
                      w="full"
                      justify="center"
                      align="center"
                    >
                      <Button
                        as="a"
                        href="https://apps.apple.com/gb/app/hubzo/id6499236087"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="unstyled"
                        h="auto"
                        p={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexShrink={0} // Prevent shrinking
                        _hover={{
                          transform: "translateY(-2px)",
                          opacity: 0.85,
                        }}
                        transition="all 0.2s"
                      >
                        <Image
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
                          alt="Download on the App Store"
                          h={{ base: "44px", sm: "48px", md: "60px" }} // Slightly larger
                          minW={{ base: "140px", sm: "160px", md: "200px" }} // Controlled width
                          maxW={{ base: "140px", sm: "160px", md: "200px" }}
                          objectFit="contain"
                        />
                      </Button>
                      <Button
                        as="a"
                        href="https://play.google.com/store/apps/details?id=app.hubzo.android"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="unstyled"
                        h="auto"
                        p={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexShrink={0} // Prevent shrinking
                        _hover={{
                          transform: "translateY(-2px)",
                          opacity: 0.85,
                        }}
                        transition="all 0.2s"
                      >
                        <Image
                          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                          alt="Get it on Google Play"
                          h={{ base: "64px", sm: "70px", md: "84px" }} // Slightly larger
                          minW={{ base: "160px", sm: "180px", md: "220px" }} // Controlled width
                          maxW={{ base: "160px", sm: "180px", md: "220px" }}
                          objectFit="contain"
                          transform="scale(0.95)" // Slightly reduce Google Play badge size to match App Store
                        />
                      </Button>
                    </Stack>
                  </Box>
                </VStack>
              </MotionBox>

              {/* Hero Image */}
              <MotionBox
                position="relative"
                display="block"
                order={{ base: 1, lg: 2 }}
                mb={{ base: 4, lg: 0 }}
              >
                <Box
                  position="absolute"
                  inset={-4}
                  bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                  opacity={0.1}
                  borderRadius="2xl"
                  filter="blur(40px)"
                />
                <Image
                  src="https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/placeholder.webp?alt=media&token=e6c46955-c9b9-4b03-94be-0998fed5f0bb"
                  alt="Friends enjoying a party"
                  w="full"
                  rounded="2xl"
                  shadow="2xl"
                  objectFit="cover"
                  h={{ base: "300px", lg: "650px" }}
                  position="relative"
                  zIndex={1}
                  transform={{ base: "none", lg: "rotate(2deg)" }}
                />
                <Box
                  position="absolute"
                  inset={0}
                  bg="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%)"
                  borderRadius="2xl"
                  zIndex={2}
                  transform={{ base: "none", lg: "rotate(2deg)" }}
                />
              </MotionBox>
            </Grid>
          </Container>

          {/* Only show other marketing sections for new users */}
          {!isReturningUser && (
            <>
              {/* Key Stats Section */}

              {false && (
                <Box py={24} bg="white" position="relative">
                  {/* Background decoration */}
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    w="50%"
                    h="100%"
                    bg="linear-gradient(135deg, #4F46E510 0%, #7C3AED10 100%)"
                    transform="skewY(-12deg)"
                    transformOrigin="top right"
                  />

                  <Container maxW="container.xl" position="relative">
                    <VStack spacing={16}>
                      {/* Section Header */}
                      <VStack
                        spacing={4}
                        textAlign="center"
                        maxW="2xl"
                        mx="auto"
                      >
                        <Text
                          color="purple.600"
                          fontWeight="semibold"
                          fontSize="sm"
                          textTransform="uppercase"
                          letterSpacing="wide"
                        >
                          Our Community
                        </Text>
                        <Heading
                          fontSize={{ base: "3xl", md: "4xl" }}
                          lineHeight="shorter"
                          fontWeight="bold"
                        >
                          Join London's Most Exciting{" "}
                          <Text
                            as="span"
                            bgGradient="linear(to-r, purple.500, blue.500)"
                            bgClip="text"
                          >
                            Social Adventure
                          </Text>
                        </Heading>
                      </VStack>

                      {/* Stats Grid */}
                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacing={{ base: 8, lg: 12 }}
                        w="full"
                        maxW="4xl"
                        mx="auto"
                      >
                        {/* Events Stat */}
                        <VStack
                          bg="white"
                          p={8}
                          rounded="2xl"
                          borderWidth="1px"
                          borderColor="gray.100"
                          shadow="lg"
                          transition="all 0.3s"
                          _hover={{
                            transform: "translateY(-8px)",
                            shadow: "xl",
                            borderColor: "purple.100",
                          }}
                          position="relative"
                          overflow="hidden"
                        >
                          <Box
                            position="absolute"
                            top="-20%"
                            right="-20%"
                            w="200px"
                            h="200px"
                            bg="purple.500"
                            opacity={0.05}
                            borderRadius="full"
                            filter="blur(40px)"
                          />
                          <Box
                            p={5}
                            bg="purple.50"
                            rounded="2xl"
                            color="purple.500"
                            mb={3}
                            transform="rotate(-5deg)"
                            transition="all 0.3s"
                            _hover={{ transform: "rotate(0deg)" }}
                          >
                            <Icon as={FaCalendarCheck} boxSize={12} />
                          </Box>
                          <Text
                            fontSize="5xl"
                            fontWeight="bold"
                            bgGradient="linear(to-r, purple.500, blue.500)"
                            bgClip="text"
                            mb={2}
                          >
                            300+
                          </Text>
                          <Text
                            fontSize="xl"
                            color="gray.700"
                            fontWeight="semibold"
                          >
                            Events & Counting
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            textAlign="center"
                          >
                            From rooftop socials to weekend adventures 🎉
                          </Text>
                        </VStack>

                        {/* Members Stat */}
                        <VStack
                          bg="white"
                          p={8}
                          rounded="2xl"
                          borderWidth="1px"
                          borderColor="gray.100"
                          shadow="lg"
                          transition="all 0.3s"
                          _hover={{
                            transform: "translateY(-8px)",
                            shadow: "xl",
                            borderColor: "blue.100",
                          }}
                          position="relative"
                          overflow="hidden"
                        >
                          <Box
                            position="absolute"
                            top="-20%"
                            right="-20%"
                            w="200px"
                            h="200px"
                            bg="blue.500"
                            opacity={0.05}
                            borderRadius="full"
                            filter="blur(40px)"
                          />
                          <Box
                            p={5}
                            bg="blue.50"
                            rounded="2xl"
                            color="blue.500"
                            mb={3}
                            transform="rotate(5deg)"
                            transition="all 0.3s"
                            _hover={{ transform: "rotate(0deg)" }}
                          >
                            <Icon as={FaUserFriends} boxSize={12} />
                          </Box>
                          <Text
                            fontSize="5xl"
                            fontWeight="bold"
                            bgGradient="linear(to-r, blue.500, cyan.500)"
                            bgClip="text"
                            mb={2}
                          >
                            10k+
                          </Text>
                          <Text
                            fontSize="xl"
                            color="gray.700"
                            fontWeight="semibold"
                          >
                            Amazing Members
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            textAlign="center"
                          >
                            Young professionals making connections 🤝
                          </Text>
                        </VStack>

                        {/* Cities Stat */}
                        <VStack
                          bg="white"
                          p={8}
                          rounded="2xl"
                          borderWidth="1px"
                          borderColor="gray.100"
                          shadow="lg"
                          transition="all 0.3s"
                          _hover={{
                            transform: "translateY(-8px)",
                            shadow: "xl",
                            borderColor: "pink.100",
                          }}
                          position="relative"
                          overflow="hidden"
                        >
                          <Box
                            position="absolute"
                            top="-20%"
                            right="-20%"
                            w="200px"
                            h="200px"
                            bg="pink.500"
                            opacity={0.05}
                            borderRadius="full"
                            filter="blur(40px)"
                          />
                          <Box
                            p={5}
                            bg="pink.50"
                            rounded="2xl"
                            color="pink.500"
                            mb={3}
                            transform="rotate(-5deg)"
                            transition="all 0.3s"
                            _hover={{ transform: "rotate(0deg)" }}
                          >
                            <Icon as={FaMapMarkerAlt} boxSize={12} />
                          </Box>
                          <Text
                            fontSize="5xl"
                            fontWeight="bold"
                            bgGradient="linear(to-r, pink.500, red.500)"
                            bgClip="text"
                            mb={2}
                          >
                            5
                          </Text>
                          <Text
                            fontSize="xl"
                            color="gray.700"
                            fontWeight="semibold"
                          >
                            Cities & Growing
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            textAlign="center"
                          >
                            Expanding across UK & Europe 🌍
                          </Text>
                        </VStack>
                      </SimpleGrid>

                      {/* Trust Indicators */}
                      <Box
                        bg="purple.50"
                        p={6}
                        rounded="2xl"
                        maxW="3xl"
                        mx="auto"
                        position="relative"
                        overflow="hidden"
                      >
                        <Box
                          position="absolute"
                          top="-50%"
                          right="-20%"
                          w="60%"
                          h="200%"
                          bg="purple.500"
                          opacity={0.05}
                          transform="rotate(-12deg)"
                          filter="blur(40px)"
                        />
                        <VStack spacing={4}>
                          <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            color="purple.700"
                            textAlign="center"
                          >
                            Join the most exciting social community in London!
                            ✨
                          </Text>
                          <HStack
                            spacing={{ base: 4, md: 8 }}
                            flexWrap="wrap"
                            justify="center"
                          >
                            <Text fontSize="sm" color="purple.600">
                              Private Profiles
                            </Text>
                            <Text
                              color="purple.300"
                              display={{ base: "none", md: "block" }}
                            >
                              •
                            </Text>
                            <Text fontSize="sm" color="purple.600">
                              ✅ Verified Members
                            </Text>
                            <Text
                              color="purple.300"
                              display={{ base: "none", md: "block" }}
                            >
                              •
                            </Text>
                            <Text fontSize="sm" color="purple.600">
                              🎯 Smart Matching
                            </Text>
                          </HStack>
                        </VStack>
                      </Box>
                    </VStack>
                  </Container>
                </Box>
              )}

              {/* Mobile Apps Section */}

              {false && (
                <Box py={20} bg="white" position="relative" overflow="hidden">
                  {/* Background decoration */}
                  <Box
                    position="absolute"
                    top="-30%"
                    right="-20%"
                    w="70%"
                    h="140%"
                    bg="linear-gradient(135deg, #4F46E580 0%, #7C3AED80 100%)"
                    opacity={0.05}
                    transform="rotate(-12deg)"
                    borderRadius="3xl"
                    filter="blur(80px)"
                  />

                  <Container maxW="container.xl">
                    <Grid
                      templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                      gap={{ base: 8, lg: 16 }}
                      alignItems="center"
                    >
                      {/* Text Content */}
                      <VStack spacing={8} align="start">
                        <VStack spacing={4} align="start">
                          <Text
                            color="purple.600"
                            fontWeight="semibold"
                            fontSize="sm"
                            textTransform="uppercase"
                            letterSpacing="wide"
                          >
                            Get The App
                          </Text>
                          <Heading
                            fontSize={{ base: "3xl", md: "4xl" }}
                            lineHeight="shorter"
                            fontWeight="bold"
                          >
                            Never Miss An{" "}
                            <Text
                              as="span"
                              bgGradient="linear(to-r, purple.500, blue.500)"
                              bgClip="text"
                            >
                              Invite
                            </Text>
                          </Heading>
                          <Text fontSize="lg" color="gray.600" maxW="lg">
                            Get notified instantly about new events and stay
                            connected with the Hubzo community - all from your
                            phone.
                          </Text>
                        </VStack>

                        {/* App Store Buttons */}
                        <Stack
                          direction="row"
                          spacing={4}
                          w="full"
                          maxW={{ base: "280px", sm: "md" }}
                          mx={{ base: "auto", sm: 0 }}
                          align="center"
                        >
                          <Button
                            as="a"
                            href="https://apps.apple.com/gb/app/hubzo/id6499236087"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="unstyled"
                            h="auto"
                            p={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            w={{ base: "full", sm: "auto" }}
                            _hover={{
                              transform: "translateY(-2px)",
                              opacity: 0.85,
                            }}
                            transition="all 0.2s"
                          >
                            <Image
                              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
                              alt="Download on the App Store"
                              h={{ base: "40px", md: "56px" }}
                              minW={{ base: "135px", md: "185px" }}
                              maxW={{ base: "135px", md: "185px" }}
                              objectFit="contain"
                            />
                          </Button>
                          <Button
                            as="a"
                            href="https://play.google.com/store/apps/details?id=app.hubzo.android"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="unstyled"
                            h="auto"
                            p={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            w={{ base: "full", sm: "auto" }}
                            _hover={{
                              transform: "translateY(-2px)",
                              opacity: 0.85,
                            }}
                            transition="all 0.2s"
                          >
                            <Image
                              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                              alt="Get it on Google Play"
                              h={{ base: "60px", md: "90px" }}
                              minW={{ base: "155px", md: "230px" }}
                              maxW={{ base: "155px", md: "230px" }}
                              objectFit="contain"
                              transform={{
                                base: "scale(0.92)",
                                md: "scale(0.88)",
                              }}
                            />
                          </Button>
                        </Stack>

                        {/* Key Features */}
                        <HStack spacing={4} pt={4}>
                          <HStack spacing={2} color="gray.600">
                            <Icon as={FaBell} boxSize={4} />
                            <Text fontSize="sm" fontWeight="medium">
                              Push Notifications
                            </Text>
                          </HStack>
                          <Text color="gray.300">•</Text>
                          <HStack spacing={2} color="gray.600">
                            <Icon as={FaShare} boxSize={4} />
                            <Text fontSize="sm" fontWeight="medium">
                              Easy Sharing
                            </Text>
                          </HStack>
                          <Text color="gray.300">•</Text>
                          <HStack spacing={2} color="gray.600">
                            <Icon as={FaMobile} boxSize={4} />
                            <Text fontSize="sm" fontWeight="medium">
                              Native Experience
                            </Text>
                          </HStack>
                        </HStack>

                        {/* Social Proof */}
                        <Box pt={8}>
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="medium"
                          >
                            Download now to stay updated with exclusive events
                            📱
                          </Text>
                        </Box>
                      </VStack>

                      {/* App Screenshots */}
                      <Box position="relative">
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          gap={6}
                          transform={{ base: "none", lg: "rotate(12deg)" }}
                        >
                          <Image
                            src="https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-07%20at%2002.15.29.png?alt=media&token=f46f755d-7659-4a58-970e-9f946847fe44"
                            alt="Hubzo App Event Details"
                            rounded="3xl"
                            shadow="2xl"
                            transform="translateY(-20px)"
                            border="8px solid white"
                            maxH="600px"
                            objectFit="contain"
                          />
                          <Image
                            src="https://firebasestorage.googleapis.com/v0/b/hubzo-app.appspot.com/o/Screenshot%202024-12-07%20at%2001.29.08.png?alt=media&token=4e9276b3-8f9b-48a3-b7ab-be48705a7783"
                            alt="Hubzo App Event List"
                            rounded="3xl"
                            shadow="2xl"
                            transform="translateY(20px)"
                            border="8px solid white"
                            maxH="600px"
                            objectFit="contain"
                          />
                        </Grid>
                        {/* Add decorative elements */}
                        <Box
                          position="absolute"
                          top="50%"
                          left="50%"
                          transform="translate(-50%, -50%)"
                          w="120%"
                          h="120%"
                          bg="purple.500"
                          opacity={0.05}
                          borderRadius="full"
                          filter="blur(60px)"
                          zIndex={-1}
                        />
                      </Box>
                    </Grid>
                  </Container>
                </Box>
              )}

              {/* Recent Meetups Section */}
              {/* <RecentMeetupsSection navigate={navigate} /> */}

              {/* Instagram Reels Section */}

              {false && (
                <Box py={20} bg="white">
                  <Container maxW="container.xl">
                    <VStack spacing={12}>
                      <VStack spacing={3} textAlign="center">
                        <Text
                          color="purple.600"
                          fontWeight="semibold"
                          fontSize="sm"
                          textTransform="uppercase"
                          letterSpacing="wide"
                        >
                          Community Stories
                        </Text>
                        <Heading
                          fontSize={{ base: "3xl", md: "4xl" }}
                          fontWeight="bold"
                          color="gray.900"
                          maxW="xl"
                          textAlign="center"
                          lineHeight="shorter"
                        >
                          Hear From Our Members
                        </Heading>
                        <Text
                          color="gray.600"
                          fontSize={{ base: "md", md: "lg" }}
                          maxW="xl"
                        >
                          Watch how smart invites helped Sarah find her perfect
                          social circle
                        </Text>
                      </VStack>

                      <Box w="full" maxW="xl" mx="auto">
                        <Box
                          position="relative"
                          borderRadius="2xl"
                          overflow="hidden"
                          bg="white"
                          borderWidth="1px"
                          borderColor="gray.200"
                          shadow="sm"
                          h="600px"
                        >
                          <Box
                            as="iframe"
                            src="https://www.instagram.com/p/DC-kFTEIKi_/embed"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            scrolling="no"
                            allowTransparency="true"
                          />
                        </Box>
                      </Box>
                    </VStack>
                  </Container>
                </Box>
              )}

              {/* How It Works Section */}

              {false && (
                <Box pb={20} bg={colors.bgColor}>
                  <Container maxW="container.xl">
                    <VStack spacing={16}>
                      <VStack spacing={6} textAlign="center">
                        <Heading
                          size="2xl"
                          position="relative"
                          _after={{
                            content: '""',
                            position: "absolute",
                            bottom: "-12px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "60px",
                            height: "3px",
                            bgGradient: "linear(to-r, blue.500, purple.500)",
                            borderRadius: "full",
                          }}
                        >
                          POPULAR{" "}
                          <Text
                            as="span"
                            fontStyle="italic"
                            bgGradient="linear(to-r, blue.500, purple.500)"
                            bgClip="text"
                          >
                            MEETUPS
                          </Text>
                        </Heading>
                        <Text
                          fontSize="xl"
                          color={colors.subtitleColor}
                          display="flex"
                          alignItems="center"
                          gap={2}
                          mt={4}
                          bg="purple.50"
                          px={6}
                          py={3}
                          rounded="full"
                          fontWeight="medium"
                        >
                          <Icon as={FaMapMarkerAlt} color="red.400" />
                          <Text
                            as="span"
                            fontWeight="semibold"
                            bgGradient="linear(to-r, blue.500, purple.500)"
                            bgClip="text"
                          >
                            London
                          </Text>
                          <Text as="span" fontSize="xl">
                            🇬
                          </Text>
                        </Text>
                        <HStack
                          spacing={4}
                          p={4}
                          bg="purple.50"
                          rounded="full"
                          color="purple.600"
                          fontSize="sm"
                          fontWeight="medium"
                        >
                          <Icon as={FaLock} />
                          <Text>
                            Members receive personalized invitations based on
                            their interests
                          </Text>
                        </HStack>
                      </VStack>

                      <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 3 }}
                        spacing={8}
                        w="full"
                      >
                        {popularMeetups.map((meetup, index) => (
                          <MotionBox
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            viewport={{ once: true }}
                          >
                            <Box
                              p={8}
                              bg="white"
                              rounded="2xl"
                              borderWidth="1px"
                              borderColor={colors.borderColor}
                              position="relative"
                              overflow="hidden"
                              transition="all 0.2s"
                              _hover={{
                                transform: "translateY(-4px)",
                                shadow: "lg",
                              }}
                            >
                              {/* Background Gradient */}
                              <Box
                                position="absolute"
                                top="-20%"
                                right="-20%"
                                w="200px"
                                h="200px"
                                bg={meetup.color}
                                opacity={0.05}
                                borderRadius="full"
                                filter="blur(40px)"
                              />

                              <VStack align="start" spacing={6}>
                                <HStack spacing={4} w="full">
                                  <Box
                                    p={4}
                                    bg={`${meetup.color}15`}
                                    rounded="xl"
                                    fontSize="2xl"
                                  >
                                    {meetup.icon}
                                  </Box>
                                  <VStack align="start" spacing={1}>
                                    <Heading size="md">{meetup.title}</Heading>
                                    <Text
                                      fontSize="sm"
                                      color={colors.subtitleColor}
                                    >
                                      {meetup.description}
                                    </Text>
                                  </VStack>
                                </HStack>

                                <Box
                                  p={4}
                                  bg="gray.50"
                                  rounded="xl"
                                  w="full"
                                  fontSize="sm"
                                >
                                  <VStack align="start" spacing={3}>
                                    <HStack color="gray.600">
                                      <Icon as={FaUserFriends} />
                                      <Text fontWeight="medium">
                                        {meetup.demographics}
                                      </Text>
                                    </HStack>
                                    <HStack
                                      justify="space-between"
                                      w="full"
                                      color="gray.500"
                                    >
                                      <Text>{meetup.frequency}</Text>
                                      <Text>•</Text>
                                      <Text>
                                        {meetup.avgAttendees} attendees
                                      </Text>
                                    </HStack>
                                  </VStack>
                                </Box>

                                <HStack
                                  spacing={4}
                                  fontSize="sm"
                                  w="full"
                                  justify="space-between"
                                >
                                  <HStack
                                    color="purple.600"
                                    bg="purple.50"
                                    p={2}
                                    px={3}
                                    rounded="full"
                                    fontSize="xs"
                                  >
                                    <Icon as={FaUserFriends} />
                                    <Text>Popular Activity</Text>
                                  </HStack>
                                  <HStack
                                    color="purple.600"
                                    bg="purple.50"
                                    p={2}
                                    px={3}
                                    rounded="full"
                                    fontSize="xs"
                                  >
                                    <Icon as={FaLock} />
                                    <Text>Invitation Only</Text>
                                  </HStack>
                                </HStack>
                              </VStack>
                            </Box>
                          </MotionBox>
                        ))}
                      </SimpleGrid>

                      <VStack spacing={6} pt={8}>
                        <Button
                          size="lg"
                          bg={colors.primaryGradient}
                          color="white"
                          px={8}
                          h={14}
                          fontSize="lg"
                          fontWeight="semibold"
                          rounded="full"
                          rightIcon={<FaArrowRight />}
                          onClick={() => navigate("/choose_name")}
                          _hover={{
                            transform: "translateY(-2px)",
                            shadow: "lg",
                            opacity: 0.95,
                          }}
                          transition="all 0.2s"
                        >
                          Join Hubzo
                        </Button>
                        <Text fontSize="sm" color={colors.subtitleColor}>
                          Get invited to meetups that match your interests • No
                          public profiles • Privacy guaranteed
                        </Text>
                      </VStack>
                    </VStack>
                  </Container>
                </Box>
              )}

              {/* How It Works Section */}

              {false && (
                <Box py={20} bg={colors.cardBg}>
                  <Container maxW="container.xl">
                    <VStack spacing={16}>
                      <VStack spacing={6} textAlign="center">
                        <Heading
                          size="2xl"
                          position="relative"
                          _after={{
                            content: '""',
                            position: "absolute",
                            bottom: "-12px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "60px",
                            height: "3px",
                            bgGradient: "linear(to-r, blue.500, purple.500)",
                            borderRadius: "full",
                          }}
                        >
                          HOW IT{" "}
                          <Text
                            as="span"
                            fontStyle="italic"
                            bgGradient="linear(to-r, blue.500, purple.500)"
                            bgClip="text"
                          >
                            WORKS
                          </Text>
                        </Heading>
                        <Text
                          fontSize="xl"
                          color={colors.subtitleColor}
                          maxW="800px"
                          bgGradient="linear(to-r, blue.600, purple.600)"
                          bgClip="text"
                          fontWeight="medium"
                          letterSpacing="tight"
                          mt={4}
                        >
                          Your journey to meaningful connections, powered by
                          smart matching
                        </Text>
                      </VStack>

                      <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacing={8}
                        w="full"
                        maxW="1200px"
                        mx="auto"
                      >
                        {steps.map((step, index) => (
                          <MotionBox
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            viewport={{ once: true }}
                          >
                            <VStack
                              bg="white"
                              rounded="2xl"
                              overflow="hidden"
                              height="full"
                              position="relative"
                              borderWidth="1px"
                              borderColor={colors.borderColor}
                              shadow="sm"
                              spacing={0}
                              _hover={{
                                transform: "translateY(-4px)",
                                shadow: "lg",
                                transition: "all 0.2s",
                              }}
                            >
                              {/* Number Badge */}
                              <Circle
                                size="40px"
                                bg={step.color}
                                color="white"
                                fontSize="lg"
                                fontWeight="bold"
                                position="absolute"
                                top={4}
                                right={4}
                                shadow="md"
                              >
                                {step.step}
                              </Circle>

                              {/* Image Section */}
                              <Box
                                position="relative"
                                w="full"
                                bg={`${step.color}10`}
                                p={8}
                                pt={16}
                              >
                                <Image
                                  src={step.image}
                                  alt={step.title}
                                  w="full"
                                  h="160px"
                                  objectFit="contain"
                                />
                              </Box>

                              {/* Content Section */}
                              <VStack
                                p={8}
                                spacing={4}
                                align="start"
                                bg="white"
                                h="full"
                              >
                                {/* Highlight Tag */}
                                <HStack
                                  bg={`${step.color}15`}
                                  color={step.color}
                                  px={3}
                                  py={1}
                                  rounded="full"
                                  fontSize="xs"
                                  fontWeight="semibold"
                                >
                                  <Icon as={FaLock} fontSize="10px" />
                                  <Text>{step.highlight}</Text>
                                </HStack>

                                <Heading size="md" color={colors.textColor}>
                                  {step.title}
                                </Heading>

                                <Text
                                  fontSize="sm"
                                  color={colors.subtitleColor}
                                  lineHeight="tall"
                                >
                                  {step.description}
                                </Text>
                              </VStack>
                            </VStack>
                          </MotionBox>
                        ))}
                      </SimpleGrid>

                      {/* Call to Action */}
                      <Box
                        bg="white"
                        p={8}
                        rounded="2xl"
                        shadow="sm"
                        borderWidth="1px"
                        borderColor={colors.borderColor}
                        maxW="2xl"
                        w="full"
                        textAlign="center"
                      >
                        <VStack spacing={6}>
                          <Heading size="md" color={colors.textColor}>
                            Ready to find your kind of people?
                          </Heading>
                          <Text color={colors.subtitleColor}>
                            Join thousands of verified members enjoying
                            meaningful connections
                          </Text>
                          <Button
                            size="lg"
                            bg={colors.primaryGradient}
                            color="white"
                            px={8}
                            rounded="full"
                            rightIcon={<FaArrowRight />}
                            onClick={() => navigate("/choose_name")}
                            _hover={{
                              transform: "translateY(-2px)",
                              shadow: "lg",
                              opacity: 0.95,
                            }}
                            transition="all 0.2s"
                          >
                            Get Started Now
                          </Button>
                        </VStack>
                      </Box>
                    </VStack>
                  </Container>
                </Box>
              )}

              {/* Testimonials Section */}
              {/* <TestimonialsSection /> */}

              {/* FAQ Section */}
              {/* <FAQSection navigate={navigate} /> */}

              {/* Footer */}
              <Box
                borderTop="1px"
                borderColor={colors.borderColor}
                bg={colors.cardBg}
                position="relative"
                overflow="hidden"
              >
                {/* Add subtle gradient background */}
                <Box
                  position="absolute"
                  bottom="-50%"
                  left="-20%"
                  w="140%"
                  h="100%"
                  bg="linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%)"
                  opacity={0.03}
                  transform="rotate(-3deg)"
                />

                <Container maxW="container.xl" py={20}>
                  <VStack spacing={16}>
                    {/* Top Footer */}
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      justify="space-between"
                      align={{ base: "start", md: "center" }}
                      w="full"
                      gap={10}
                    >
                      <SimpleGrid columns={{ base: 2, md: 3 }} spacing={10}>
                        <VStack align="start" spacing={4}>
                          <Text
                            fontWeight="semibold"
                            fontSize="sm"
                            textTransform="uppercase"
                            color={colors.textColor}
                          >
                            Company
                          </Text>
                          <VStack align="start" spacing={3}>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                              onClick={() => navigate("/about-us")}
                            >
                              About Us
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                              onClick={() =>
                                window.open(
                                  "https://www.linkedin.com/company/hubzo",
                                  "_blank"
                                )
                              }
                            >
                              Careers
                            </Button>
                            {/* <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                            >
                              Press
                            </Button> */}
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                              onClick={() => navigate("/contact")}
                            >
                              Contact
                            </Button>
                          </VStack>
                        </VStack>

                        <VStack align="start" spacing={4}>
                          <Text
                            fontWeight="semibold"
                            fontSize="sm"
                            textTransform="uppercase"
                            color={colors.textColor}
                          >
                            Resources
                          </Text>
                          <VStack align="start" spacing={3}>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                            >
                              Blog
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                            >
                              Community Guidelines
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                              onClick={() => navigate("/safety-tips")}
                            >
                              Safety Tips
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                              onClick={() => navigate("/faq")}
                            >
                              FAQ
                            </Button>
                          </VStack>
                        </VStack>

                        <VStack align="start" spacing={4}>
                          <Text
                            fontWeight="semibold"
                            fontSize="sm"
                            textTransform="uppercase"
                            color={colors.textColor}
                          >
                            Legal
                          </Text>
                          <VStack align="start" spacing={3}>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                              onClick={() => navigate("/privacy-policy")}
                            >
                              Privacy Policy
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                            >
                              Terms of Service
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                            >
                              Cookie Policy
                            </Button>
                            <Button
                              variant="link"
                              fontSize="sm"
                              color={colors.subtitleColor}
                            >
                              Safety
                            </Button>
                          </VStack>
                        </VStack>
                      </SimpleGrid>
                    </Flex>

                    {/* App Download Section with larger badges */}

                    {false && (
                      <Box
                        w="full"
                        pt={10}
                        borderTop="1px"
                        borderColor={colors.borderColor}
                      >
                        <Stack
                          direction="row"
                          spacing={4}
                          align="center"
                          justify="center"
                        >
                          <Button
                            as="a"
                            href="https://apps.apple.com/gb/app/hubzo/id6499236087"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="unstyled"
                            h="auto"
                            p={0}
                            _hover={{ opacity: 0.85 }}
                            transition="opacity 0.2s"
                          >
                            <Image
                              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
                              alt="Download on the App Store"
                              h={{ base: "40px", md: "56px" }}
                              minW={{ base: "135px", md: "185px" }}
                              maxW={{ base: "135px", md: "185px" }}
                              objectFit="contain"
                            />
                          </Button>
                          <Button
                            as="a"
                            href="https://play.google.com/store/apps/details?id=app.hubzo.android"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="unstyled"
                            h="auto"
                            p={0}
                            _hover={{ opacity: 0.85 }}
                            transition="opacity 0.2s"
                          >
                            <Image
                              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                              alt="Get it on Google Play"
                              h={{ base: "60px", md: "90px" }}
                              minW={{ base: "155px", md: "230px" }}
                              maxW={{ base: "155px", md: "230px" }}
                              objectFit="contain"
                              transform={{
                                base: "scale(0.92)",
                                md: "scale(0.88)",
                              }}
                            />
                          </Button>
                        </Stack>
                      </Box>
                    )}

                    {/* Bottom Footer */}
                    <Flex
                      pt={8}
                      borderTop="1px"
                      borderColor={colors.borderColor}
                      w="full"
                      direction={{ base: "column", sm: "row" }}
                      justify="space-between"
                      align="center"
                      gap={4}
                    >
                      <HStack
                        spacing={4}
                        color={colors.subtitleColor}
                        fontSize="sm"
                      >
                        <Text>© 2024 Hubzo</Text>
                        <Text>•</Text>
                        <Text>Made with ❤️ in London</Text>
                      </HStack>

                      <HStack spacing={6}>
                        <IconButton
                          aria-label="Instagram"
                          icon={<FaInstagram size={24} />}
                          variant="ghost"
                          color="gray.500"
                          size="lg"
                          rounded="full"
                          _hover={{
                            transform: "translateY(-2px)",
                            color: "#E1306C",
                            bg: "gray.50",
                          }}
                          _active={{
                            transform: "translateY(0)",
                            color: "#C13584",
                          }}
                          transition="all 0.2s"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/hubzo.social/",
                              "_blank"
                            )
                          }
                        />
                        <IconButton
                          aria-label="TikTok"
                          icon={<FaTiktok size={22} />}
                          variant="ghost"
                          color="gray.500"
                          size="lg"
                          rounded="full"
                          _hover={{
                            transform: "translateY(-2px)",
                            color: "#000000",
                            bg: "gray.50",
                          }}
                          _active={{
                            transform: "translateY(0)",
                            color: "#69C9D0",
                          }}
                          transition="all 0.2s"
                          onClick={() =>
                            window.open(
                              "https://www.tiktok.com/@hubzo.social",
                              "_blank"
                            )
                          }
                        />
                        <IconButton
                          aria-label="Facebook"
                          icon={<FaFacebook size={24} />}
                          variant="ghost"
                          color="gray.500"
                          size="lg"
                          rounded="full"
                          _hover={{
                            transform: "translateY(-2px)",
                            color: "#1877F2",
                            bg: "gray.50",
                          }}
                          _active={{
                            transform: "translateY(0)",
                            color: "#166FE5",
                          }}
                          transition="all 0.2s"
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/hubzo.social",
                              "_blank"
                            )
                          }
                        />
                        <IconButton
                          aria-label="LinkedIn"
                          icon={<FaLinkedin size={24} />}
                          variant="ghost"
                          color="gray.500"
                          size="lg"
                          rounded="full"
                          _hover={{
                            transform: "translateY(-2px)",
                            color: "#0077B5",
                            bg: "gray.50",
                          }}
                          _active={{
                            transform: "translateY(0)",
                            color: "#006699",
                          }}
                          transition="all 0.2s"
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/company/hubzo",
                              "_blank"
                            )
                          }
                        />
                      </HStack>
                    </Flex>
                  </VStack>
                </Container>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
